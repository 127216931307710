/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";

@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~open-iconic/font/css/open-iconic-bootstrap.css";

// Theme customization
@import "theme/theme";

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

* {
  scrollbar-width: thin;
  scrollbar-color: #cdcdcd #f0f0f0;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

*::-webkit-scrollbar-track {
  background: #f0f0f0;
}

*::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  border-radius: 20px;
  border: 3px solid #f0f0f0;
}

.happ-selected-button {
  text-decoration: underline;
  font-weight: 700;
  font-size: 30px;
}

.happ-pdf-html {
  border: 1px solid #c5c3c3c2;
  width: 90%;
  margin: auto;
  margin-top: 41px;
  padding: 4px 24px;
  box-shadow: 3px 20px 17px 15px #c5c4c475;
  background: white;
}

.happ-btn-hover:hover {
  transform: scale(1.02);
}

ul {
  width: 100%;
}

.happ-full-width {
  width: 100%;
  max-width: 100%;

  .modal-dialog {
    width: 100%;
    max-width: 100%;
  }
}

.happ-semi-width {
  width: 100%;
  max-width: 100%;

  margin-top: 70px;

  .modal-dialog {
    width: 70%;
    max-width: 70%;
  }

  @media only screen and (max-width: 600px) {
    .modal-dialog {
      width: 100%;
      max-width: 100%;
    }
  }
}

.pac-container {
  z-index: 100000 !important;
}
